.sidebar-container{
    display: flex;
    width: 15%;
    min-width: 100px;
    max-width: 250px;
    height: 100vh;
    background-color: #1a2846;
    color: white;
    align-items: flex-start;
    margin: 0px;
    padding: 10px;
    flex-direction: column;

}

.sidebar-menu-items{
    font-size: large;
    padding: 5px;
    width: 100%;
    /* text-align: center; */
    cursor: pointer;
    display: flex;
    align-items: center;
}

.active-menu-item{
    background-color: #2c3b5a;
    border-radius: 5px;
}

.sidebar-menuitem-text{
    margin-left: 15px;
}


.signout-btn{
    background-color: white;
    color: #2c3b5a;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 40px;
    width: 12%;
    min-width: 80px;
    max-width: 230px;
    text-align: center;
}
