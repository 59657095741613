.active-leverage-btn{
    color: black !important;
    background-color: white !important;
    border: black solid;
    font-weight: bold;
}

.active-leverage-btn:hover {
    color: black !important;
    background-color: white !important;
    border: black solid;
    font-weight: bold;
}