@import "tailwindcss/utilities";

body {
  /* background-color: #8EC5FC; */
  /* background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%); */
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: "Forum";
}

.padding-10 {
  padding: 10px;
}

.margin-10 {
  margin: 10px;
}

.text-align-center {
  text-align: center;
}

.full-height {
  /* height: 100%; */
  height: 100vh;
}

.full-width {
  width: 100%;
}

.full-viewport-width {
  width: 100dvw;
}

.justify-content-right {
  justify-content: right;
}

.spacer {
  margin-top: 20px;
}

.h-center {
  /* horizontal center */
  display: flex;
  justify-content: center;
}

.v-center {
  display: flex;
  align-content: center;
  align-items: center;
}

.hv-center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.hv-100 {
  width: 100%;
  height: 100%;
}

.grow {
  flex-grow: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.text-decoration-none {
  text-decoration: none;
  color: white;
}

.bgcoverFutures {
  background: url(../assets/bg1.svg);
  height: 150px;
  color: white;
  object-fit: cover;
  width: 100%;
  /* opacity: 0.8; */
}
.bgcoverSpot {
  background: url(../assets/bg2.svg);
  height: 150px;
  color: white;
  object-fit: cover;
  width: 100%;
}
.bgcoverAffiliate {
  background: url(../assets/bg3.svg);
  height: 150px;
  color: white;
  object-fit: cover;
  width: 100%;
}
.bgcoverDiv {
  position: absolute;
  top: 120px;
}

.li {
  list-style-type: none;
}
#li {
  list-style-type: none;
}
#ul {
  list-style-type: none;
}
